<!-- 中高风险地区临时隔离  By阿旭 -->
<template>
  <div class="main">
    <el-divider></el-divider>
    <div class="content">
      <div class="xxinput">
        <div class="content-box">
          <el-form
            :rules="rules"
            ref="basicInfoRef"
            :model="formInfo"
            label-width="210px"
          >
            <div class="content_residentinformation">
              <span class="blue"></span>
              <p>基本信息</p>
            </div>

            <div class="content-item">
              <el-form-item
                class="formitem"
                label="旅居地区："
                prop="livingAreaCode"
                v-if="checkForm == 1"
              >
                <el-cascader
                  ref="livingAreaRef"
                  clearable
                  style="width: 90%"
                  placeholder="可搜索地区"
                  v-model="formInfo.livingAreaCode"
                  :props="props"
                  filterable
                  @change="handleChange"
                />
              </el-form-item>
              <el-form-item
                class="formitem"
                label="旅居国家："
                prop="livingCountry"
                v-else-if="checkForm == 3 || checkForm == 2 || checkForm == 6"
              >
                <el-select
                  filterable
                  placeholder="请选择旅居国家："
                  style="width: 90%"
                  v-model="formInfo.livingCountry"
                >
                  <el-option
                    v-for="item in nationalityselet"
                    :key="item.label"
                    :label="item.label"
                    @click.native="optionClick(item.value)"
                    :value="item.label"
                  >
                    <span style="float: left">{{ item.label }}</span>
                    <span
                      style="float: right; color: #8492a6; font-size: 13px"
                      >{{ item.value }}</span
                    >
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item
                class="formitem"
                label="风险等级："
                prop="riskLevel"
              >
                <el-select
                  style="width: 90%"
                  v-model="formInfo.riskLevel"
                  placeholder="风险等级"
                >
                  <el-option
                    v-for="item in dangerselect"
                    :key="item.value"
                    :label="item.label"
                    :value="item.label"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </div>

            <div class="content-item" v-if="checkForm == 2 || checkForm == 6">
              <el-form-item
                class="formitem"
                label="入境城市："
                prop="cityEntryCode"
              >
                <el-cascader
                  v-if="!iscityEntry"
                  clearable
                  style="width: 90%"
                  :props="props"
                  v-model="formInfo.cityEntryCode"
                  @change="handleCityEntry"
                  ref="cityEntryRef"
                  filterable
                />
                <el-input
                  v-if="iscityEntry"
                  style="width: 90%"
                  v-model="formInfo.cityEntry"
                  @focus="iscityEntry = false"
                />
              </el-form-item>
              <el-form-item class="formitem" label="入境口岸：">
                <el-input
                  style="width: 90%"
                  v-model="formInfo.portEntry"
                  placeholder="请输入入境口岸"
                />
              </el-form-item>
            </div>

            <div class="content-item">
              <el-form-item
                class="formitem"
                label="来渝交通工具："
                prop="traffic"
              >
                <el-select
                  style="width: 90%"
                  class="top_left_inputnationality"
                  v-model="formInfo.traffic"
                  placeholder="请选择"
                >
                  <el-option
                    v-for="item in carselect"
                    :key="item.value"
                    :label="item.label"
                    :value="item.label"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item
                class="formitem"
                label="航班号/车次号/车牌号："
                prop="trafficNo"
              >
                <el-input
                  style="width: 90%"
                  v-model="formInfo.trafficNo"
                  placeholder="请输入"
                />
              </el-form-item>
            </div>

            <div class="content-item">
              <el-form-item class="formitem" label="户籍地：">
                <el-cascader
                  clearable
                  style="width: 90%"
                  placeholder="可搜索地区"
                  v-model="formInfo.householdPlaceCode"
                  :props="props"
                  filterable
                  @change="changeHouseholdPlace"
                  ref="householdPlaceRef"
                ></el-cascader>
              </el-form-item>
              <el-form-item class="formitem" label="座位号：" prop="seatNo">
                <el-input
                  style="width: 90%"
                  v-model="formInfo.seatNo"
                  placeholder="请输入座位号"
                />
              </el-form-item>
            </div>

            <div class="content-item">
              <el-form-item
                class="formitem"
                label="目的地："
                prop="destinationCode"
              >
                <el-cascader
                  clearable
                  style="width: 90%"
                  placeholder="可搜索地区"
                  :props="props"
                  v-model="formInfo.destinationCode"
                  filterable
                  @change="handleDestination"
                  ref="destinationRef"
                />
              </el-form-item>
              <el-form-item
                class="formitem"
                label="病例姓名："
                prop="caseName"
                v-if="checkForm == 6"
              >
                <el-input
                  style="width: 90%"
                  v-model="formInfo.caseName"
                  placeholder="请输入病例姓名"
                />
              </el-form-item>
            </div>

            <div class="content-item">
              <el-form-item class="formitem" label="所属街道：">
                <el-input
                  style="width: 90%"
                  v-model="formInfo.destinationStreet"
                  placeholder="请输入所属街道"
                />
              </el-form-item>
              <el-form-item
                class="formitem"
                label="接触时间："
                prop="contactTime"
                v-if="checkForm == 6"
              >
                <el-date-picker
                  style="width: 90%"
                  type="datetime"
                  v-model="formInfo.contactTime"
                  format="yyyy-MM-dd HH:mm:ss"
                  value-format="yyyy-MM-dd HH:mm:ss"
                  placeholder="选择时间"
                ></el-date-picker>
              </el-form-item>
              <el-form-item
                class="formitem"
                label="离开风险区时间："
                prop="leaveTime"
                v-else
              >
                <el-date-picker
                  style="width: 90%"
                  type="datetime"
                  v-model="formInfo.leaveTime"
                  format="yyyy-MM-dd HH:mm:ss"
                  value-format="yyyy-MM-dd HH:mm:ss"
                  placeholder="选择时间"
                ></el-date-picker>
              </el-form-item>
            </div>

            <div class="content-item">
              <el-form-item class="formitem" label="详细地址：">
                <el-input
                  style="width: 90%"
                  v-model="formInfo.destinationDetail"
                  placeholder="请输入详细地址"
                />
              </el-form-item>
              <el-form-item
                class="formitem"
                label="抵渝时间："
                prop="arrivalTime"
              >
                <el-date-picker
                  style="width: 90%"
                  type="datetime"
                  v-model="formInfo.arrivalTime"
                  format="yyyy-MM-dd HH:mm:ss"
                  value-format="yyyy-MM-dd HH:mm:ss"
                  placeholder="选择时间"
                  @change="reachDate"
                ></el-date-picker>
              </el-form-item>
            </div>

            <div class="content_residentinformation">
              <span class="blue"></span>
              <p>隔离时间</p>
            </div>
            <div class="content-item">
              <el-form-item
                class="formitem"
                label="开始隔离时间："
                prop="startTime"
              >
                <el-date-picker
                  style="width: 90%"
                  type="datetime"
                  v-model="formInfo.startTime"
                  format="yyyy-MM-dd HH:mm:ss"
                  value-format="yyyy-MM-dd HH:mm:ss"
                  placeholder="选择时间"
                ></el-date-picker>
              </el-form-item>
            </div>
            <div class="content_residentinformation">
              <span class="blue"></span>
              <p>入住筛查</p>
            </div>

            <div class="content-item">
              <el-form-item
                class="formitem"
                label="是否常驻居民："
                prop="residentFlag"
              >
                <el-radio-group
                  style="width: 90%"
                  v-model="formInfo.residentFlag"
                >
                  <el-radio label="1">是</el-radio>
                  <el-radio label="0">否</el-radio>
                </el-radio-group>
              </el-form-item>
            </div>

            <div class="content-item">
              <el-form-item
                class="formitem"
                label="情绪是否稳定："
                prop="emotionFlag"
              >
                <el-radio-group
                  style="width: 90%"
                  v-model="formInfo.emotionFlag"
                >
                  <el-radio label="1">是</el-radio>
                  <el-radio label="0">否</el-radio>
                </el-radio-group>
              </el-form-item>
              <el-form-item
                class="formitem"
                label="是否持24小时核酸阴性证明："
                prop="covid24hProveFlag"
              >
                <el-radio-group
                  style="width: 90%"
                  v-model="formInfo.covid24hProveFlag"
                >
                  <el-radio label="1">是</el-radio>
                  <el-radio label="0">否</el-radio>
                </el-radio-group>
              </el-form-item>
            </div>

            <div class="content-item">
              <el-form-item
                class="formitem"
                label="是否持健康绿码："
                prop="healthGreenCodeFlag"
              >
                <el-radio-group
                  style="width: 90%"
                  v-model="formInfo.healthGreenCodeFlag"
                >
                  <el-radio label="1">是</el-radio>
                  <el-radio label="0">否</el-radio>
                </el-radio-group>
              </el-form-item>
              <el-form-item
                class="formitem"
                label="入住体温："
                prop="temperatureEntry"
              >
                <el-input
                  style="width: 90%"
                  placeholder="请输入体温℃"
                  v-model="formInfo.temperatureEntry"
                ></el-input>
              </el-form-item>
            </div>

            <div class="content-item">
              <el-form-item
                class="formitem"
                label="有无咳嗽、乏力等症状："
                prop="coughFlag"
              >
                <el-radio-group style="width: 90%" v-model="formInfo.coughFlag">
                  <el-radio label="1">是</el-radio>
                  <el-radio label="0">否</el-radio>
                </el-radio-group>
              </el-form-item>
              <el-form-item
                class="formitem"
                label="是否已接种疫苗："
                prop="vaccinationFlag"
              >
                <el-radio-group
                  style="width: 90%"
                  v-model="formInfo.vaccinationFlag"
                >
                  <el-radio label="1">是</el-radio>
                  <el-radio label="0">否</el-radio>
                </el-radio-group>
              </el-form-item>
            </div>

            <div class="content-item">
              <el-form-item
                v-if="formInfo.coughFlag == '1'"
                class="formitem"
                label="症状描述："
                prop="coughDetail"
              >
                <el-input
                  style="width: 90%"
                  placeholder="请输入症状"
                  v-model="formInfo.coughDetail"
                ></el-input>
              </el-form-item>
              <el-form-item
                v-if="formInfo.vaccinationFlag == '1'"
                class="formitem"
                label="是否已完成接种："
                prop="vaccinationFinishFlag"
              >
                <el-radio-group
                  style="width: 90%"
                  v-model="formInfo.vaccinationFinishFlag"
                >
                  <el-radio label="1">是</el-radio>
                  <el-radio label="0">否</el-radio>
                </el-radio-group>
              </el-form-item>
            </div>
          </el-form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { http, serverIP } from "../../api/index";
import { subListApi } from "../../api/QuarantineRegistration/quarantineRegistrationpop";
import constants from "../../common/constants";

export default {
  data() {
    return {
      formInfo: {
        livingAreaCode: [], // 旅居地区
        cityEntryCode: [], // 入境城市
        householdPlaceCode: [], // 户籍地
        destinationCode: [], // 目的地
        livingCountry: "", // 旅居国家
        startTime: "",
        riskLevel: "高风险",
        arrivalTime: "", //抵重庆时间
        contactTime: "",
        leaveTime: "",
        caseName: "",
        trafficNo: "",
        seatNo: "",
        traffic: "",
        portEntry: "",
        destinationDetail: "",
        destinationStreet: "",
        residentFlag: "",
        coughDetail: "",
        vaccinationFinishFlag: "",
        emotionFlag:'',
        covid24hProveFlag:'',
        healthGreenCodeFlag:'',
        temperatureEntry:'',
        coughFlag:'',
        vaccinationFlag:'',
        vaccinationFinishFlag:'',
      }, //人员信息
      rules: {
        livingAreaCode: [
          { required: true, message: "请选择旅居地区", trigger: "change" },
        ],
        destinationCode: [
          { required: true, message: "请选择目的地", trigger: "change" },
        ],
        cityEntryCode: [
          { required: true, message: "请选择入境城市", trigger: "change" },
        ],
        livingCountry: [
          { required: true, message: "请选择旅居国家", trigger: "change" },
        ],
        riskLevel: [
          { required: true, message: "请选择风险等级", trigger: "change" },
        ],
        traffic: [
          { required: true, message: "请选择来渝交通工具", trigger: "blur" },
        ],
        portEntry: [
          { required: true, message: "请填写入境口岸", trigger: "blur" },
        ],
        destinationselet: [
          { required: true, message: "请选择目的地", trigger: "change" },
        ],
        trafficNo: [
          {
            required: true,
            message: "请输入航班号/车次号/车牌号",
            trigger: "blur",
          },
        ],
        caseName: [
          { required: true, message: "请输入病例姓名", trigger: "blur" },
        ],
        leaveTime: [
          { required: true, message: "请选择离开风险区时间", trigger: "blur" },
        ],
        contactTime: [
          { required: true, message: "请选择接触时间", trigger: "blur" },
        ],
        arrivalTime: [
          { required: true, message: "请选择抵达重庆时间", trigger: "blur" },
        ],
        startTime: [
          { required: true, message: "请选择开始隔离时间", trigger: "blur" },
        ],
        // emotionFlag: [{ required: true, message: "请选择情绪是否稳定", trigger: "change" }],
        // healthGreenCodeFlag: [{ required: true, message: "请选择是否持健康绿码", trigger: "change" }],
        // covid24hProveFlag: [{ required: true, message: "请选择是否持24小时核酸阴性证明", trigger: "change" }],
        // temperatureEntry: [{ required: true, message: "请输入体温℃", trigger: "blur" }],
        // coughFlag: [{ required: true, message: "请选择有无咳嗽、乏力等症状", trigger: "change" }],
        // vaccinationFlag: [{ required: true, message: "请选择是否接种疫苗", trigger: "change" }],
        coughDetail: [
          { required: true, message: "请输入症状描述", trigger: "blur" },
        ],
        vaccinationFinishFlag: [
          {
            required: true,
            message: "请选择是否已完成接种",
            trigger: "change",
          },
        ],
      },
      carselect: constants.VEHICLE_TYPE, // 交通工具
      dangerselect: constants.DANGER_TYPE, //危险等级
      nationalityselet: JSON.parse(localStorage.getItem("nationalityselet")), // 旅居国家

      // livingArea: JSON.parse(localStorage.getItem("dqlist")), //旅居地区
      // cityEntry: JSON.parse(localStorage.getItem("dqlist")), // 入境城市
      // householdPlace: JSON.parse(localStorage.getItem("dqlist")), //户籍地
      // destinationList: JSON.parse(localStorage.getItem("dqlist")), // 目的地

      props: {
        lazy: true,
        lazyLoad(node, resolve) {
          console.log(node, 'node')
          if (node.level == 0) {
            let cities = JSON.parse(localStorage.getItem("dqlist"));
            setTimeout(() => {
              resolve(cities);
            }, 10)
          } else {
            let formm = { label: node.data.value };
            http({
              method: "post",
              url: `/ohealth/api/v1/system/common/subList`,
              data: formm,
            }).then(function (response) {
              let list = response.data.data;
              const nodes = list.map((item) => ({
                value: item.value,
                label: item.label,
                leaf: item.leaf == 3,
              }));

              resolve(nodes);
            });
          }
        },
      },
    };
  },

  props: [
    // 人群分类
    "crowdTypeId",
    "checkForm",
    "redform",
  ],

  watch: {
    formInfo: {
      handler(val) {
        console.log(val, 'val')
        if (val.arrivalTime) {
          this.reachDate();
        }
        // 旅居地区 如果有数据就请求接口
        // console.log(val.livingAreaCode)
        // if (val.livingAreaCode && val.livingAreaCode.length == 3) {
        //   val.livingAreaCode.forEach((item) => {
        //     this.getSubList(item, '旅居地区')
        //   })
        // }
        // // 入境城市 如果有数据就请求接口
        // if (val.cityEntryCode.length == 3) {
        //   val.cityEntryCode.forEach(item => {
        //     this.getSubList(item, '入境城市')
        //   })
        // }
        // // 户籍地 如果有数据就请求接口
        // if (val.householdPlaceCode.length == 3) {
        //   val.householdPlaceCode.forEach(item => {
        //     this.getSubList(item, '户籍地')
        //   })
        // }
        // // 目的地 如果有数据就请求接口
        // if (val.destinationCode.length == 3) {
        //   val.destinationCode.forEach(item => {
        //     this.getSubList(item, '目的地')
        //   })
        // }
      },
      deep: true,
    },
  },

  created() {

  },
  mounted() {
    // this.$forceUpdate();

    this.$nextTick(() => {
      console.log(this.redform, 'redForm')
      // this.formInfo = {...this.redform }
      this.formInfo.livingAreaCode = this.redform.livingAreaCode
      this.formInfo.cityEntryCode =  this.redform.cityEntryCode// 旅居地区
      this.formInfo.householdPlaceCode =  this.redform.householdPlaceCode
      this.formInfo.destinationCode =  this.redform.destinationCode
      this.formInfo.livingCountry =  this.redform.livingCountry
      this.formInfo.startTime =  this.redform.startTime
      this.formInfo.riskLevele =  this.redform.riskLevel
      this.formInfo.arrivalTime =  this.redform.arrivalTime
      this.formInfo.contactTime =  this.redform.contactTime
      this.formInfo.leaveTime=  this.redform.leaveTime
      this.formInfo.caseName =  this.redform.caseName
      this.formInfo.trafficNo=  this.redform.trafficNo
      this.formInfo.seatNo=  this.redform.seatNo
      this.formInfo.traffic=  this.redform.traffic
      this.formInfo.portEntry =  this.redform.portEntry
      this.formInfo.destinationDetail =  this.redform.destinationDetail
      this.formInfo.destinationStreet =  this.redform.destinationStreet
      this.formInfo.residentFlag =  this.redform.residentFlag
      this.formInfo.coughDetail =  this.redform.coughDetail
      this.formInfo.vaccinationFinishFlag =  this.redform.vaccinationFinishFlag
    })
  },
  methods: {
    // 请求地区数据
    getSubList(areaCode, type) {
      let params = { label: areaCode };

      // 获取组件实例中存储数据用的store
      let store = this.$refs.livingAreaRef.$refs.panel.store;

      subListApi(params).then((res) => {
        let { code, data, msg } = res.data;
        if (code == 200) {
          if (type === "旅居地区") {
            let cityNode, provinceNode;

            data.forEach((list) => {
              if (list.leaf == 2) {
                provinceNode = store.nodes.find(
                  (item) => item.value === areaCode
                );
                list.leaf = false;

                // 根据provinceId获取市列表
                let cityList = data;
                // 调用store的方法将城市列表加到对应的省的children列表
                store.appendNodes(cityList, provinceNode);
                // 标记省份节点已经加载完毕
                provinceNode.loaded = true;
                // 调用panel的方法,模拟点击省的操作
                this.$refs.livingAreaRef.$refs.panel.handleExpand(provinceNode);

                cityNode = provinceNode.children.find(
                  (item) => item.value === list.value
                );

                list.leaf = true;
                let countryList = data;

                // 将区县列表加入到市节点下
                store.appendNodes(countryList, cityNode);
                // 标记市节点已经加载完毕
                cityNode.loaded = true;

                // 调用panel的方法,模拟点击市的操作
                this.$refs.livingAreaRef.$refs.panel.handleExpand(cityNode);
              } else if (list.leaf == 3) {
              }
            });
            // 调用组件的方法，将文字回显出来(省/市/区)
            this.$refs.livingAreaRef.computePresentText();
          }
        } else {
          this.$message.error(msg);
        }
      });
    },

    // 提交
    comm(callback) {
      this.$refs["basicInfoRef"].validate((valid) => {
        console.log(valid, 'child valid')
        callback && callback(valid);
        return valid
      });
      
    },

    // 重置
    resetf() {
      this.$refs.basicInfoRef.resetFields();
      this.formInfo.householdPlaceCode = [];
      this.formInfo.destinationStreet = "";
      this.formInfo.destinationDetail = "";
    },

    // 旅居地区
    handleChange(value) {
      let node = this.$refs.livingAreaRef.getCheckedNodes()[0];
      if (node) {
        let livingArea = node.pathLabels.toString();
        this.formInfo.livingArea = livingArea;
        this.formInfo.livingAreaCode = value;
      }
    },

    // 入境城市
    handleCityEntry(value) {
      let node = this.$refs.cityEntryRef.getCheckedNodes()[0];
      if (node) {
        let cityEntry = node.pathLabels.toString();
        this.formInfo.cityEntry = cityEntry;
        this.formInfo.cityEntryCode = value;
      }
    },

    // 户籍地
    changeHouseholdPlace(value) {
      let node = this.$refs.householdPlaceRef.getCheckedNodes()[0];
      if (node) {
        let householdPlace = node.pathLabels.toString();
        this.formInfo.householdPlace = householdPlace;
        this.formInfo.householdPlaceCode = value;
      }
    },

    // 	目的地
    handleDestination(value) {
      let node = this.$refs.destinationRef.getCheckedNodes()[0];
      if (node) {
        let destination = node.pathLabels.toString();
        this.formInfo.destination = destination;
        this.formInfo.destinationCode = value;
      }
    },

    optionClick(value) {
      this.formInfo.livingCountryCode = value;
    },

    // 抵渝时间
    reachDate() {
      this.$emit("changReachDate", this.formInfo.arrivalTime);
    },
  },
};
</script>

<style lang='scss' scoped>
.main {
  width: 100%;
  height: 100%;

  .content {
    .content_residentinformation {
      display: flex;
      align-items: center;
      margin-bottom: 20px;
      margin-top: 10px;
    }

    .content-box {
      margin-top: 40px;
      width: 100%;

      .content-item {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
    }
  }
}

.blue {
  width: 4px;
  height: 20px;
  background-color: #3476f5;
  margin-right: 6px;
}

.formitem {
  width: 50%;
}

.xxinput {
  margin-bottom: 20px;
}

.bottom {
  margin-top: 20px;
  display: flex;
  justify-content: center;
}
</style>
